import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { ParallaxProvider } from "react-skrollr"
//import range from 'lodash-es/range'

import SEO from "../layout/seo"
import FactsNNumUl from "./FactsNNumUlEn"

//import iotVideo from "./iotVideo"

import { MainVisualImg } from "./MainVisualImg"
import smart_0 from "../../img/smart_0.png"
import smart_1 from "../../img/smart_1.png"
import smart_2 from "../../img/smart_2.png"
import smart_3 from "../../img/smart_3.png"
import main_1 from "../../img/icons/main_01.png"
import main_2 from "../../img/icons/main_02.png"
import main_3 from "../../img/icons/main_03.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Visual() {
  const scrolled = useScroll(500)

  return (
    <ParallaxProvider
      init={{
        smoothScrollingDuration: 500,
        smoothScrolling: true,
        forceHeight: false,
      }}
    >
      <div>
        <SEO title="M2MTech" />
        <div className="mainWrap">
          <div className="sectionWrap mainVisual">
            <div className="backgroundAnimation">
              <p></p>
            </div>
            <div className="zIndex">
              <div className="imgWrap">
                <MainVisualImg />
              </div>
              <div className={classNames("textWrap", scrolled && "test")}>
                <h2>
                  ELSA AI IoT x ELSA.Cloud
                  <span className="HeadLKor">
                    Connect and extend AI devices and digital industry
                    applications for optimal efficiency and results.
                  </span>
                </h2>
                <p className="bodyText">
                  Through the intelligent IoT Gateway
                  <span className="tmText">TM</span>connecting the local site to
                  the cloud center through ELSA solution of M2MTech Co., Ltd.,
                  the company provides high-quality, safe IoT service
                  environment by applying the latest international IoT standards
                  such as communication and security between nodes and web
                  security standards. Flexible server operation and use in large
                  and small sites can be charged as much as possible, enabling
                  efficient cost reduction.
                </p>
              </div>
            </div>
            <p className="scrollDown">
              <span>READ MORE</span>
              <br />
              <svg height="11" width="15">
                <polygon points="0,0 15,0 7.5,11" className="triangle" />
              </svg>
            </p>
          </div>
          <div className="sectionWrap factsNNum">
            <FactsNNumUl />
            <div className="facts">
              <p className="factsCite">
                | 'Smart Cities and Cost Savings' Report from ABI Research, US
              </p>
              The way people use buildings is constantly changing, but once
              buildings and cities are built with well-designed long-term
              assets, they are not easy to change. Innovative technologies
              through IoT driven by cognitive computing can dramatically reduce
              long-term operating costs and enhance user experience.
            </div>
            <ul className="whatWeOffer">
              <li>
                <p className="imgWrap">
                  <img src={main_1} alt="" />
                </p>
                <p className="titleWrap">cost reduction</p>
                <p className="listWrap">
                  <span>energy saving</span>
                  <span>Failure Remote Response</span>
                  <span>Deterioration forecast/life management</span>
                  <span>manpower efficiency</span>
                  <span>Prevention of Large Accidents</span>
                  <span>Reasonable charging system</span>
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={main_2} alt="" />
                </p>
                <p className="titleWrap">Speed and accuracy</p>
                <p className="listWrap">
                  <span>Security</span>
                  <span>Real-time noise/vibration monitoring</span>
                  <span>Alert on mobile devices</span>
                  <span>Emergency Situation Prediction</span>
                  <span>Financial management</span>
                  <span>Equipment, Maintain Building Maintenance</span>
                </p>
              </li>
              <li>
                <p className="imgWrap">
                  <img src={main_3} alt="" />
                </p>
                <p className="titleWrap">Efficiency and safety</p>
                <p className="listWrap">
                  <span>Customized Operation</span>
                  <span>Automated logging, statistics, and reporting</span>
                  <span>Image Recoding &amp; Replay</span>
                  <span>pc mobile device access</span>
                  <span>Secured Data Processing</span>
                  <span>Space efficiency</span>
                </p>
              </li>
            </ul>
          </div>

          <div className="sectionWrap elsaSmart">
            <div className="backgroundAnimation">
              <p></p>
            </div>
            <div className="zIndex">
              <div className="textWrap">
                <h2>Smart Home, Smart Building, Smart City</h2>
                <p className="bodyText">
                  Elsa Solution serves as a conduit for communication between
                  machines and people across most industries. We applied the
                  latest remote monitoring technologies such as artificial
                  intelligence AI, cloud, web/app PC/mobile platforms, and
                  integrated the Comprehensive Business Automation System (ERP)
                  to enable high-speed high-capacity data processing, aging, and
                  status error checking in real time, and to efficiently
                  generate profits and manage human resources.
                </p>
              </div>
              <ul className="ul01">
                <li>
                  <p className="imgWrap">
                    <img src={smart_0} alt="" />
                  </p>
                  Escalator
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_1} alt="" />
                  </p>
                  Elevator/Lift
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_2} alt="" />
                  </p>
                  Parking Tower
                </li>
                <li>
                  <p className="imgWrap">
                    <img src={smart_3} alt="" />
                  </p>
                  Logistics
                </li>
              </ul>
              <ul className="ul02">
                <li>Security </li>
                <li>Smart Vending Machine</li>
                <li>Smart Factory</li>
                <li>ICS/SCADA</li>
                <li>Smart Home</li>
                <li>Smart City</li>
              </ul>
            </div>
            <p className="moreBtn">
              <Link to="/ElsaEn/">LEARN MORE ABOUT ELSA</Link>
            </p>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  )
}

export default Visual
