import React from "react"
import LayoutEn from "../components/layoutEn"
import SEO from "../components/layout/seo"

import Visual from "../components/index/VisualEn"
//import IngCover from "../components/ingCover"
import "../styles/App.css"

const IndexPage = () => (
  <LayoutEn>
    <SEO title="M2MTech" />
    <Visual />
    {/*<IngCover />*/}
  </LayoutEn>
)

export default IndexPage
