import React from "react"
import { Spring } from "react-spring/renderprops"

function FactsNNumUl() {
  return (
    <ul className="factsNNumUl">
      <li>
        <div className="numNumder">
          <Spring
            from={{ number: 0 }}
            to={{ number: 71 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          &#37;
        </div>
        <p className="numDesc">
          Maintenance cost in lifecycle of a building is
          <br />
          71% of the total expenditure.
        </p>
      </li>
      <li>
        <div className="numNumder">
          &#36;
          <Spring
            from={{ number: 0 }}
            to={{ number: 5 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          trillion
        </div>
        <p className="numDesc">
          global urban operating costs due to smart city technology
          <br />
          are expected to decrease by $5 trillion a year, by 2022.
        </p>
      </li>
      <li>
        <div className="numNumder">
          <Spring
            from={{ number: 0 }}
            to={{ number: 30 }}
            config={{ precision: 0.1 }}
          >
            {props => <div>{props.number}</div>}
          </Spring>
          &#37;
        </div>
        <p className="numDesc">
          A city in the US reduced Annual city and building <br />
          maintenance costs with Smart City Tech by 30%.
        </p>
      </li>
    </ul>
  )
}
export default FactsNNumUl
